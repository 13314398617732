// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.background {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none !important;
}

.thumbnail {
  vertical-align: top;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-player {
  height: 100%;
  width: 100%;
}

*:focus {
  outline: none;
}

.twemoji {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/css/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,SAAS;EACT,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,WAAW;AACb;;AAEA;;;;EAIE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,YAAY;AACd","sourcesContent":["ul {\n  border: 0;\n  font: inherit;\n  margin: 0;\n  padding: 0;\n  vertical-align: baseline;\n}\n\n.background {\n  background-repeat: no-repeat;\n  background-size: cover;\n  height: 100%;\n  width: 100%;\n}\n\na,\na:visited,\na:hover,\na:active {\n  text-decoration: none !important;\n}\n\n.thumbnail {\n  vertical-align: top;\n  max-width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.youtube-player {\n  height: 100%;\n  width: 100%;\n}\n\n*:focus {\n  outline: none;\n}\n\n.twemoji {\n  height: 20px;\n  width: 20px;\n  vertical-align: middle;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
